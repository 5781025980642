import Swal from "sweetalert2";
import {Fancybox} from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import Swiper from "swiper";
import {EffectFade, Scrollbar, Autoplay, Navigation, Pagination, Thumbs, Grid} from 'swiper/modules';
import 'swiper/css/grid';
import 'swiper/css/effect-fade';
import lozad from 'lozad';
import 'jquery-datetimepicker';
import intlTelInput from "intl-tel-input";

function getCSRFToken() {
    var cookieValue = null;
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.startsWith('csrftoken=')) {
            cookieValue = cookie.substring('csrftoken='.length, cookie.length);
            break;
        }
    }
    return cookieValue;
}

function date_picker_init() {
    var now = new Date();
    var currentHours = now.getHours();

    var minDate = currentHours >= 17 ? now.setDate(now.getDate() + 1) : now;

    $('.datepicker__input').datetimepicker({
        format: 'Y/m/d',
        minDate: minDate,
        timepicker: false,
        onChangeDateTime: function (currentDate, $input) {
            $('.timepicker__input').datetimepicker('reset'); // Сбрасываем время при изменении даты
        }
    });

    $('.timepicker__input').datetimepicker({
        datepicker: false,
        format: 'H:i',
        minTime: function () {
            var selectedDate = $('.datepicker__input').datetimepicker('getValue');
            var now = new Date();

            if (selectedDate) {
                var isToday = selectedDate.getDate() === now.getDate() &&
                    selectedDate.getMonth() === now.getMonth() &&
                    selectedDate.getFullYear() === now.getFullYear();

                return isToday ? (currentHours < 9 ? '09:00' : ('0' + currentHours).slice(-2) + ':00') : '09:00';
            }

            return '09:00';
        },
        maxTime: '18:00',
        onShow: function (ct, $input) {
            var now = new Date();
            var selectedDate = $('.datepicker__input').datetimepicker('getValue');
            var currentHours = now.getHours();

            if (selectedDate) {
                var isToday = selectedDate.getDate() === now.getDate() &&
                    selectedDate.getMonth() === now.getMonth() &&
                    selectedDate.getFullYear() === now.getFullYear();

                this.setOptions({
                    minTime: isToday ? (currentHours < 9 ? '09:00' : ('0' + currentHours).slice(-2) + ':00') : '09:00',
                    maxTime: '18:00'
                });
            } else {
                this.setOptions({
                    minTime: '09:00',
                    maxTime: '18:00'
                });
            }
        }
    });
}

function initIntlTelInput() {
    const input = document.querySelector("#id_phone");
    const submitButton = document.querySelector("#reserve-form button[type='submit']");
    let userInteracted = false;

    if (input && submitButton) {
        const iti = intlTelInput(input, {
            initialCountry: "HU",
            loadUtilsOnInit: () => import("intl-tel-input/utils")
        });

        function toggleSubmitButton() {
            if (iti.isValidNumber()) {
                input.classList.remove('invalid');
                input.classList.add('valid');
                $('#phone-error').text('');
                submitButton.disabled = false;
            } else {
                input.classList.remove('valid');
                input.classList.add('invalid');
                $('#phone-error').text(userInteracted ? 'Invalid phone number' : '');
                submitButton.disabled = true;
            }
        }

        input.addEventListener('input', function () {
            userInteracted = true;
            toggleSubmitButton();
        });

        input.addEventListener('blur', toggleSubmitButton);
        toggleSubmitButton();

        return iti;
    }

    return null;
}


(function ($) {
    $(document).ready(function () {

        initCart('#total_count', '#total_price');

        $('.cart__popup-open').on("click", function (e) {
            e.preventDefault();
            Swal.fire({
                html: `
                    <div class="swal_inner">
                        <div class="cart">
                            <p class="cart__title h2">Basket</p>
                            <div class="cart__table">
                                <div class="cart__table_item">
                                    <a href="#" class="cart__table_img">
                                        <img class="lozad" src="/static/images/product.png" alt="img">
                                    </a>
                                    <div class="cart__table_caption">
                                        <a href="#" class="cart__table_title h3">Mexico Chiapas Zafiro SHG EP</a>
                                        <p>500 gram</p>
                                        <p class="cart__table_quantity">1 piece</p>
                                    </div>
                                    <p class="cart__table_price">70 HUF</p>
                                    <span class="cart__table_remove" title="remove"></span>
                                </div>
                                <div class="cart__table_item">
                                    <a href="#" class="cart__table_img">
                                        <img class="lozad" src="/static/images/product.png" alt="img">
                                    </a>
                                    <div class="cart__table_caption">
                                        <a href="#" class="cart__table_title h3">Mexico Chiapas Zafiro SHG EP</a>
                                        <p>500 gram</p>
                                        <p class="cart__table_quantity">1 piece</p>
                                    </div>
                                    <p class="cart__table_price">70 HUF</p>
                                    <span class="cart__table_remove" title="remove"></span>
                                </div>
                            </div>
                            <div class="cart__total">
                                <p>Total</p>
                                <p class="product-price">140 HUF</p>
                            </div>
                            <div class="cart__buttons-group">
                                <a href="#" class="button button--light">Continue shopping</a>
                                <a href="#" class="button">Place an order</a>
                            </div>
                        </div>
                    </div>
                      `,
                showCloseButton: true,
                showConfirmButton: false,
                showCancelButton: false,
            })

        });

        date_picker_init();

        $('.reserve-table__popup-open').on("click", function (e) {
            e.preventDefault();

            $.ajax({
                url: reserveFormUrl,
                type: 'GET',
                success: function (html) {
                    Swal.fire({
                        html: html,
                        showCloseButton: true,
                        showConfirmButton: false,
                        showCancelButton: false,
                        didOpen: () => {
                            date_picker_init();
                            const iti = initIntlTelInput();

                            $('#reserve-form').on('submit', function (event) {
                                event.preventDefault();

                                const phoneInput = document.querySelector("#id_phone");
                                if (phoneInput && iti && iti.isValidNumber()) {
                                    const fullPhoneNumber = iti.getNumber();
                                    $('#id_phone').val(fullPhoneNumber);
                                }

                                let formData = $(this).serialize();

                                $.ajax({
                                    url: reserveFormUrl,
                                    type: 'POST',
                                    data: formData,
                                    headers: {
                                        'X-CSRFToken': getCSRFToken()
                                    },
                                    success: function (response) {
                                        if (response.success) {
                                            window.location.href = response.redirect_url;
                                        } else {
                                            Swal.update({
                                                html: response.form_html
                                            });
                                            date_picker_init();
                                            initIntlTelInput();
                                        }
                                    },
                                    error: function () {
                                        Swal.fire('Error', 'Something went wrong!', 'error');
                                    }
                                });
                            });
                        }
                    });
                },
                error: function () {
                    Swal.fire('Error', 'Failed to load the form.', 'error');
                }
            });
        });


        const observer = lozad();
        observer.observe();

        $('.navbar_toggler').on('click', function () {
            $(this).toggleClass('active')
            $('.header__container').toggleClass('active')
            $('.header nav').toggleClass('active')
        })

        $('.header nav a').on('click', function () {
            $('.header nav').removeClass('active')
            $('.header__container').removeClass('active')
            $('.navbar_toggler').removeClass('active')
        })

        $('.header .submenu_show .header__nav-link').on('click', function () {
            $(this).next().addClass('open')
            $(this).parent().addClass('open')
        })

        $('.header .header__nav-submenu--back').on('click', function () {
            $(this).parent().removeClass('open')
            $(this).parent().parent().removeClass('open')
        })

        const sectionSwiperParentSelector = document.querySelectorAll('.section__slider_wrap');
        sectionSwiperParentSelector.forEach((slider_wrap) => {
            const sectionSwiperSelector = slider_wrap.querySelector('.section__slider');
            if (sectionSwiperSelector != null) {
                const sectionSwiperPrev = slider_wrap.querySelector('.swiper-button-prev');
                const sectionSwiperNext = slider_wrap.querySelector('.swiper-button-next');
                const sectionSwiperPagination = slider_wrap.querySelector('.swiper-pagination');
                const sectionSwiper = new Swiper(sectionSwiperSelector, {
                    modules: [Navigation, Pagination],
                    slidesPerView: 1,
                    spaceBetween: 12,
                    navigation: {
                        prevEl: sectionSwiperPrev,
                        nextEl: sectionSwiperNext,
                    },
                    pagination: {
                        el: sectionSwiperPagination,
                        clickable: true,
                    },
                    breakpoints: {
                        576: {
                            slidesPerView: 2,
                            spaceBetween: 24,
                        },
                        992: {
                            slidesPerView: 3,
                            spaceBetween: 34,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 40,
                        }
                    }
                });
            }
        })

        const indexGallerySwiperSelector = document.querySelector('.index-gallery__slider');
        const indexGallerySwiper = new Swiper(indexGallerySwiperSelector, {
            modules: [Navigation, Pagination, Grid],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 34,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                    grid: {
                        rows: 2,
                        fill: 'row'
                    },
                }
            }
        });

        const reviewsGallerySwiperSelector = document.querySelector('.reviews__slider');
        const reviewsGallerySwiper = new Swiper(reviewsGallerySwiperSelector, {
            modules: [Navigation, Pagination, Grid],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".reviews-button-prev",
                nextEl: ".reviews-button-next",
            },
            pagination: {
                el: '.reviews-pagination',
                clickable: true,
            },

            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                    grid: {
                        rows: 2,
                        fill: 'row'
                    },
                }
            }
        });

        const productGallerySwiperSelector = document.querySelector('.product-gallery__slider');
        const productGallerySwiper = new Swiper(productGallerySwiperSelector, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        });

        const blogSwiperSelector = document.querySelector('.blog__slider');
        const blogSwiper = new Swiper(blogSwiperSelector, {
            modules: [Navigation, Pagination],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },

            breakpoints: {
                576: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 34,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                }
            }
        });

        const sliderMainSwiperSelector = document.querySelector('.swiper_gallery_main');
        const sliderTrumbSwiperSelector = document.querySelector('.swiper_gallery_trumb');

        if (sliderMainSwiperSelector != null && sliderTrumbSwiperSelector != null) {

            const ThumbSwiper = new Swiper(sliderTrumbSwiperSelector, {
                modules: [Grid],
                spaceBetween: 8,
                slidesPerView: 2,
                grid: {
                    rows: 3,
                    fill: 'row'
                },
                breakpoints: {
                    768: {}
                }
            });
            const mainSwiper = new Swiper(sliderMainSwiperSelector, {
                modules: [EffectFade, Navigation, Pagination, Thumbs],
                slidesPerView: 1,
                spaceBetween: 12,
                effect: "fade",
                thumbs: {
                    swiper: ThumbSwiper,
                },
                navigation: {
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                },
                pagination: {
                    el: ".swiper-pagination",
                    type: "fraction",
                },
            });
        }


        Fancybox.bind("[data-fancybox]");


        $('.anchor[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                let target = $(this.hash);
                let menuHeight = $('header').outerHeight();
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - menuHeight
                    }, 1000);
                    return false;
                }
            }
        });


        const goToTopButton = $('.footer__go-to-top');

        $(window).on('scroll', function () {
            if ($(window).scrollTop() > $(window).height()) {
                goToTopButton.fadeIn();
            } else {
                goToTopButton.fadeOut();
            }
        });

        goToTopButton.click(function (e) {
            e.preventDefault();
            $('html, body').animate({scrollTop: 0}, 1000);
        });


    });
}(jQuery));